@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    padding: 0;
    margin: 0;
}

html,
body,
#root,
.App {
    width: 100%;
    height: 100%;
}

.App>.row {
    min-height: 100%;
}

body {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.mr-35 {
    margin-right: 35px;
}

/* Roboto fonts */
.roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
}

/* inter fonts */
.inter-font {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}


.container {
    max-width: 660px;
}

.content-container {
    max-width: 998px;
    margin: auto;
}

.f-75 {
    font-size: 0.75rem !important;
}

.card__title {
    font-size: 16px !important;
    font-family: "Roboto", sans-serif;
    margin-bottom: 1rem !important;
}

.f-26 {
    font-size: 26px !important;
}

.f-22 {
    font-size: 22px !important;
}

/* responsive css */
@media(min-width:768px) {
    .sidebar {
        height: 100vh !important;
        position: sticky;
        top: 0;
        z-index: 9;
        max-width: 500px;
    }

    .mobile_device_logo {
        display: none;
    }
}

@media (max-width:1024px) {

    .area_radio_btn {

        right: 10px;
        top: 10px;
    }
}

@media(max-width:767px) {
    .sidebar {
        display: none !important;
    }

    div#root {
        overflow-x: hidden;
    }

    .fw-bold {
        font-size: 19px;
    }

    .mobile_hide_text span {
        display: none;
    }

    .p-csm-0 {
        padding: 0 !important;
    }

    .px-csm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .csm-hidden {
        display: none !important;
    }
}

/* responsive css end */

@media(max-width:375px) {
    button.Ripple-parent {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }
}

.main__steps>.step-row-2 {
    margin: 0 !important;
}

.areas_section:has(input.area_radio:checked) {
    background: #EBF6ED;
}

.areas_section button {
    max-width: 220px;
    margin: auto;
}

.areas_section.card-body {
    padding: 10px !important;
}

.areas_section:has(input.area_radio:checked) button {
    background: #212529;
    color: #fff;
}

.area_radio_btn {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 27px;
    height: 27px;
    background: #E4E4E4;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.area_radio:checked+.area_radio_btn img {
    opacity: 1 !important;
}

.area_radio {
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
}

.area_radio_btn img {
    opacity: 0;
    right: 0px;
    position: relative;
    top: 0;
    border-radius: 50%;
}

input.area_radio:checked+img {
    opacity: 1;
}

.agreement_check input {
    width: 22px;
    height: 22px;
    border-radius: 8px !important;
}

.agreement_check label {
    width: calc(100% - 20px);
    margin-left: 10px;
    text-align: left;
}

.form-control {
    background: #f2f2f282;
    height: 44px;
    border-color: #BDBDBD;
}

.form-control::placeholder {
    color: #BDBDBD;
}

::-webkit-scrollbar {
    display: none;
}

.addressList {
    cursor: pointer;
}

.disable_btn {
    pointer-events: none !important;
    opacity: .4;
}

.list-group {
    max-height: 200px;
    overflow: auto;
}

.list-group::-webkit-scrollbar {
    display: block !important;
    -webkit-appearance: none !important;
    width: 7px !important;
}

.list-group::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: rgba(0, 0, 0, .5) !important;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5) !important;
}

.card li {
    list-style: none;
    position: relative;
    padding-left: 15px;
    line-height: 22px;
}

.card li:not(.list-style-none):before {
    content: '';
    width: 5px;
    height: 5px;
    background: #000;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 6px;
}

button:not(.btn-outline-dark) {
    background: #27311E !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__view-calendar-icon input {
    padding: 5px 10px 5px 30px !important;
    display: inline-block !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.5rem;
    box-sizing: content-box;
    top: 50%;
    transform: translateY(-50%);
    fill: #AAAAAA;
}
.react-confirm-alert-body {
    max-width: 500px !important;
    width: 96% !important;
    margin: auto !important;
}
.react-confirm-alert-body h1 {
    padding-bottom: 1rem !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 25px !important;
}
.react-confirm-alert-body{
    font-family: "Inter", sans-serif !important;
}
.react-confirm-alert-body button{
    font-size: 13.6px !important;   
}

.react-confirm-alert-body .ehDhhb {
    background: transparent !important;
    border: 2px solid rgb(17, 24, 39) !important;
    color: rgb(2, 2, 3) !important;
}
.react-confirm-alert-button-group {
    padding-top: 1rem !important;
    justify-content: space-around !important;
}
.react-confirm-alert-overlay{
    background: rgb(0 0 0 / 60%) !important;
}
.modal-content {
    border: none;
}
.modal-dialog{
	max-width:700px;
}
.f-95 {
    font-size: 0.95rem !important;
}

.form-control:focus {
    border-color: #4cd270 !important;
    box-shadow: 0 0 0 .25rem rgb(76 210 112 / 25%) !important;
}